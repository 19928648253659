<template>
    <div>
        <page-title :heading="$t('settings.lang_cashierModules')" show-previous-button :permission-add="true" :subheading="$t('settings.lang_cashierModules')" :icon=icon></page-title>
        <div class="app-main__inner">
            <modules-edit/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import ModulesEdit from "../../../../components/settings/cashier/cashiermodules/ModulesEdit";



    export default {
        components: {
            ModulesEdit,
            PageTitle,
        },
        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>