<template>
  <v-container fluid>
    <div class="content mt-6 pa-0">
      <div v-if="currentTab === 'tab1'">
        <div class="main-card mb-3 card pa-0 ma-0">
          <div
            :class="[$vuetify.theme.dark ? 'dark-bg rounded' : '']"
            class="card-body pa-0"
          >
            <div
              :class="[$vuetify.theme.dark ? 'white--text' : '']"
              class="card-title card-header-tab card-header"
            >
              <div
                class="card-header-title font-size-lg text-capitalize font-weight-normal"
              >
                {{ $t("generic.lang_template") }} :
                {{ modules.name }}
              </div>
            </div>

            <v-divider class="pa-0 ma-0" />

            <v-card>
              <v-card-text>
                <v-row align="center" justify="center">
                  <draggable @update="onUpdate($event, 0)" class="row">
                    <v-col
                      cols="12"
                      :md="modules.grid"
                      class="pt-0 mt-0"
                      v-for="item in modulesArr1"
                      :key="item.id"
                    >
                      <v-item-group>
                        <v-container>
                          <v-card
                            class="d-flex align-center"
                            color="blue lighten-2"
                            dark
                            height="100"
                          >
                            <div class="display-3 flex-grow-1 text-center">
                              <h3>{{ $t(item.name_translation) }}</h3>
                            </div>
                            <v-btn
                              @click="deleteItem(item)"
                              color="grey darken-3"
                              dark
                              icon
                            >
                              <v-icon>
                                {{
                                  active ? "mdi-delete" : "mdi-delete-outline"
                                }}
                              </v-icon>
                            </v-btn>
                          </v-card>
                        </v-container>
                      </v-item-group>
                    </v-col>
                    <v-col :md="modules.grid" class="pt-0 mt-0" cols="12">
                      <v-dialog max-width="800px" scrollable v-model="dialog2">
                        <template v-slot:activator="{ on, attrs }">
                          <v-item-group>
                            <v-container>
                              <v-card
                                class="d-flex align-center"
                                color="blue lighten-4"
                                dark
                                height="100"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div
                                  class="color: black; display-3 flex-grow-1 text-center"
                                >
                                  <h3 style="color: black">+</h3>
                                </div>
                              </v-card>
                            </v-container>
                          </v-item-group>
                        </template>
                        <v-card max-width="800">
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                :key="ite.name"
                                column
                                v-for="ite in (this.modulesArr || []).filter((elt) => (elt.enabled == false || elt.enabled == undefined) && parseInt(elt.isExtra) == 0 && checkModule(elt.moduleID))"
                              >
                                <v-list-item-content @click="push(ite)">
                                  <v-list-item-title
                                    class="title font-weight-medium"
                                    v-text="$t(ite.name_translation)"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </draggable>

                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <draggable @update="onUpdate($event, 1)" class="row">
                    <v-col
                      cols="12"
                      md="12"
                      class="pt-0 mt-0"
                      v-for="item in modulesArr2"
                      :key="item.id"
                    >
                      <v-item-group>
                        <v-container>
                          <v-card
                            class="d-flex align-center"
                            color="blue lighten-2"
                            dark
                            height="100"
                          >
                            <div class="display-3 flex-grow-1 text-center">
                              <h3>{{ $t(item.name_translation) }}</h3>
                            </div>
                            <v-btn
                              @click="deleteItem(item)"
                              color="grey darken-3"
                              dark
                              icon
                            >
                              <v-icon>
                                {{
                                  active ? "mdi-delete" : "mdi-delete-outline"
                                }}
                              </v-icon>
                            </v-btn>
                          </v-card>
                        </v-container>
                      </v-item-group>
                    </v-col>
                    <v-col class="pt-0 mt-0" cols="12" md="12">
                      <v-dialog max-width="800px" scrollable v-model="dialog">
                        <template v-slot:activator="{ on, attrs }">
                          <v-item-group>
                            <v-container>
                              <v-card
                                class="d-flex align-center"
                                color="blue lighten-4"
                                dark
                                height="100"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div
                                  class="color: black; display-3 flex-grow-1 text-center"
                                >
                                  <h3 style="color: black">+</h3>
                                </div>
                              </v-card>
                            </v-container>
                          </v-item-group>
                        </template>
                        <v-card max-width="800">
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                :key="ite.name"
                                column
                                v-for="ite in (this.modulesArr || []).filter((elt) => (elt.enabled == false || elt.enabled == undefined) && parseInt(elt.isExtra) == 1 && checkModule(elt.moduleID))"
                              >
                                <v-list-item-content @click="push(ite)">
                                  <v-list-item-title
                                    class="title font-weight-medium"
                                    v-text="$t(ite.name_translation)"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </draggable>

                  <v-col class="text-right" cols="12">
                    <v-btn
                      :disabled="loading"
                      :loading="loading"
                      @click="goBacktoItem"
                      color="error"
                    >
                      {{ this.$t("generic.lang_prev") }}
                    </v-btn>

                    <v-btn
                      :disabled="loading"
                      :loading="loading"
                      @click="SetModules"
                      color="success"
                    >
                      {{ this.$t("generic.lang_save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <!---->
      </div>

      <!-- confirmation config -->
      <div v-if="currentTab === 'tab2'"></div>
    </div>
  </v-container>
</template>

<script>
import Tabs from "vue-tabs-with-active-line";
import draggable from "vuedraggable";
import { ENDPOINTS } from "../../../../config";
import { Events } from "../../../../plugins/events";
import { mapState } from "vuex";

export default {
  components: {
    draggable,
    Tabs,
  },
  name: "CashierModulesComponent",
  data: () => {
    return {
      dialog: false,
      dialog2: false,
      currentTab: "tab1",
      loading: false,
      active: false,
      modules: {},
      modulesArr: [],
      modulesPos: [],
      name: "",
    };
  },
  mounted() {
    this.loadModules();
  },
  computed: {
    ...mapState(["permissions"]),
    tabs: function () {
      return [
        {
          title: this.$t("settings.lang_templates"),
          value: "tab1",
        },
        {
          title: this.$t("settings.lang_assignTemplates"),
          value: "tab2",
        },
      ];
    },
    modulesArr1() {
      let modulesArr1 = this.modulesArr.filter((elt) => elt.enabled == true && parseInt(elt.isExtra) === 0 && this.checkModule(elt.moduleID));

      for (let index = 0; index < modulesArr1.length; index++) {
        modulesArr1[index].position = index;
      }
      
      return modulesArr1;
    },
    modulesArr2() {
      let modulesArr2 = this.modulesArr.filter((elt) => elt.enabled == true && parseInt(elt.isExtra) === 1 && this.checkModule(elt.moduleID));

      for (let index = 0; index < modulesArr2.length; index++) {
        modulesArr2[index].position = index;
      }

      return modulesArr2;
    }
  },
  methods: {
    loadModules() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.SETTINGS.CASHIERMODULES.TEMPLATES.GET, {
          uuid: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            this.modules = res.data.template;
            this.modules.template_uuid = this.modules.uuid;
            this.modulesPos = res.data.template.modules.position;

            this.modulesArr = res.data.template.modules.sort((a, b) =>
              a.position > b.position ? 1 : b.position > a.position ? -1 : 0
            );
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    SetModules() {
      this.loading = true;
      this.modules.modules = this.modulesArr.map((x) => x);

      this.axios
        .post(
          ENDPOINTS.SETTINGS.CASHIERMODULES.TEMPLATES.SETMODULES,
          this.modules
        )
        .then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("erp.lang_itemSuccessfulAdded"),
              color: "success",
            });
          } else if (res.data.STATUS === "FAILED") {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg,
            });
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: this.$t("generic.lang_anErrorOccurredPLSTryAgain"),
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkModule(module_id) {
      if (module_id === 0) return true;

      let check = this.permissions.modules.find(
        (module) => module === module_id
      );

      if (!check) return false;

      return true;
    },
    push: function (item) {
      item.enabled = true;

      this.modulesArr = this.modulesArr.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );
    },
    deleteItem: function (item) {
      item.enabled = false;

      this.modulesArr = this.modulesArr.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );
    },
    onUpdate(evt, val) {
      let newIndex = evt.newIndex; 
      let oldIndex = evt.oldIndex; 

      let tmpArr = [];

      if (val == 0) {
        tmpArr = this.modulesArr1;
      } else if (val == 1) {
        tmpArr = this.modulesArr2;
      }

      const newPosition = tmpArr[newIndex].position;

      if (evt.newIndex < evt.oldIndex) {
        for (newIndex; newIndex < oldIndex; newIndex++) {
          tmpArr[newIndex].position = tmpArr[newIndex + 1].position;
        }
      } else {
        for (newIndex; newIndex > oldIndex; newIndex--) {
          tmpArr[newIndex].position = tmpArr[newIndex - 1].position;
        }
      }

      tmpArr[oldIndex].position = newPosition;

      this.modulesArr = this.modulesArr.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );
    },
    onEND: function (evt) {
      // this.name = evt.item.innerText;
      //this.modules.modules[parseInt(this.name) - 1].position = this.newindex;
    },
    goBacktoItem: function () {
      this.$router.push("/settings/cashierSettings/cashiermodules");
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },
};
</script>

<style scoped>
.tabs > .tabs__item,
.tabs__item {
  width: auto !important;
  z-index: 1 !important;
}

.tabs__item_active,
.tabs__active-line,
.shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}
</style>
